<template>
  <div>
    <v-progress-linear
      color="deep-orange accent-4"
      indeterminate
      height="6"
      v-if="loading"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col
          v-for="product in products"
          :key="product.id"
          cols="12"
          sm="6"
          lg="4"
        >
          <v-card class="mt-3 mb-3 mx-auto" outlined :elevation="3">
            <v-img
              height="350"
              :src="product.images[0].src"
              alt="product.name"
              v-if="product.images.length > 0"
              class="black"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-list-item three-line>
              <v-list-item-content>
                <div>
                  <v-btn
                    :href="
                      'http://1stclass.ar/categoria-producto/' +
                      product.categories[0].slug
                    "
                    target="_blank"
                    text
                  >
                    <span class="overline">
                      {{ product.categories[0].name }}
                    </span>
                  </v-btn>
                </div>
                <a
                  :href="'http://1stclass.ar/producto/' + product.slug"
                  target="_blank"
                >
                  <v-list-item-title class="headline ml-1 mb-1">
                    {{ product.name }}
                  </v-list-item-title>
                </a>
                <Variations :variations="product.variations" />
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear
        color="deep-orange accent-4"
        indeterminate
        height="6"
        v-if="loading"
      ></v-progress-linear>
      <v-btn
        class="ma-2"
        :loading="loading"
        :disabled="loading || noMore"
        color="deep-orange"
        @click="loadMore"
        block
        dark
      >
        Cargar más
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import Variations from "./Variations.vue";
// TODO: mover api a un servicio aparte
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const api = new WooCommerceRestApi({
  url: "https://1stclass.ar",
  consumerKey: "ck_5f25f269f135f7e91e3546de768cd00432da934d",
  consumerSecret: "cs_83bcc0c14e95856c0ccf723c354b2bb6d80ede8c",
  version: "wc/v3",
  queryStringAuth: true,
});

export default {
  name: "Products",
  components: {
    Variations,
  },
  data: () => ({
    products: [],
    page: 1,
    per_page: 10,
    noMore: false,
    loading: false,
  }),
  created() {
    this.getProducts();
  },
  methods: {
    async loadMore() {
      this.page += 1;
      console.log(this.page);
      this.getProducts();
    },
    async getProducts() {
      this.loading = true;
      let products;
      try {
        products = await api.get("products", {
          page: this.page,
          per_page: this.per_page,
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        return;
      }
      if (products.data.length < this.per_page) this.noMore = true;
      let ps = [...products.data];
      for (let i = 0; i < ps.length; i++) {
        let p = ps[i];
        ps[i].variations = await this.getVariations(p.id);
        ps[i].active = true;
      }
      this.products = [...this.products, ...ps];
      this.loading = false;
    },
    async getVariations(id) {
      try {
        const variations = await api.get(`products/${id}/variations`, {
          per_page: "100",
        });
        return variations.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
